import { TFunction } from 'react-i18next'

import { CallManagementLabels, Labels } from '@dentalux/ui-library-core'

import { CallTaskStates } from '../../@types/CallTask'
import { Conversation, MessageDirection } from '../../@types/Conversation'
import { getAppointmentDetailsLabels } from '../../helpers/labels'

// We want the conversations that have not been read yet
const isUnread = ({ hasBeenRead }: Conversation) => !hasBeenRead

export const getUnreadConversations = (conversations: Conversation[]): number => conversations.filter(isUnread).length

export const getUnansweredConversationsCount = (conversations: Conversation[]): number => {
  const result = conversations.filter((conversation: Conversation) => {
    return conversation.latestMessages[0]?.direction === MessageDirection.INBOUND
  })

  return result.length
}

export const getCallManagementLabels = (t: TFunction): CallManagementLabels => ({
  card: {
    callbackTime: {
      calledPast: t('called_at_time_past'),
      calledToday: t('called_at_time_today'),
      ignoredPast: t('ignored_at_time_past'),
      ignoredToday: t('ignored_at_time_today'),
      notReachedPast: t('not_reached_at_past'),
      notReachedToday: t('not_reached_at_today'),
      days: t('time_spent_day_plural'),
      hours: t('time_spent_hours_plural'),
      minutes: t('time_spent_minutes'),
      seconds: 'MISSING TRANSLATION SECONDS',
    },
    name: {
      anonymousCaller: t('anonymous_caller'),
    },
    newAppointment: t('add_new_appointment_to_patient_title'),
    status: {
      ignoreCall: t('ignore_call'),
      ignoreForm: {
        cancel: t('cancel_call_action'),
        ignoreCall: t('ignore_call'),
        placeholder: t('comment_call_action'),
        submit: t('confirm_call_action_ignore'),
      },
      statusCalling: t('calling'),
      statusLabel: Object.entries(CallTaskStates).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: t(`callState_${value}`),
        }),
        {}
      ),
    },
    today: t('today'),
    yesterday: t('yesterday'),
  },
  openCalls: t('open_calls'),
  resolvedCalls: t('resolved_calls'),
})

export const getTodoTabLabels = (t: TFunction): Labels => ({
  openTodos: t('todo_list.open'),
  doneTodos: t('todo_list.done'),
  patientDetails: t('patient_details'),
  ignoreTodo: t('ignore_todo'),
  recallTodo: t('recall_todo'),
  call: t('call'),
  description: {
    costPlan: t('todo_list.modal.description.cost_plan'),
    default: t('todo_list.modal.description.default'),
    followUpAppointment: t('todo_list.modal.description.followup_appointment'),
  },
  origin: {
    autoAcceptedHkpNoFollowup: t('todo_list.origin.autoAcceptedHkpNoFollowup'),
    autoHkpFollowup: t('todo_list.origin.autoHkpFollowup'),
    autoOnlineCancellation: t('todo_list.origin.autoOnlineCancellation'),
    manual: t('todo_list.origin.manual'),
  },
  category: {
    followupAppointment: t('todo_list.followup_appointment'),
    appointmentRescheduling: t('todo_list.modal.category.reschedule_appointment'),
    openHKP: t('todo_list.modal.category.open_hkp'),
    costPlan: t('todo_list.modal.category.cost_plan'),
  },
  modal: {
    callPatient: t('call_patient'),
    title: t('todo_list.modal.title'),
    openInCalendar: t('open_in_calendar'),
    followupDescription: t('todo_list.modal.followup_description'),
    scheduleAppointment: t('todo_list.modal.schedule_appointment'),
  },
  status: {
    todo: t('to_do'),
    done: t('task_status_done'),
    recall: t('recall'),
  },
  comment: {
    placeholder: t('comment_call_action'),
    cancel: t('cancel_button'),
    ignore: t('confirm_call_action_ignore'),
    done: t('confirm_call_action_done'),
    recall: t('confirm_call_action_recall'),
  },
  sort: {
    relevance: t('todo_list.relevance'),
    newest: t('todo_list.newest'),
    oldest: t('todo_list.oldest'),
  },
  filter: {
    taskTypes: t('todo_list.task_types'),
    appointmentTypes: t('todo_list.appointment_types'),
  },
})

export const getOpenAppointmentsLabels = (t: TFunction) => ({
  schedule: t('schedule'),
  appointmentDetails: getAppointmentDetailsLabels(t),
})
