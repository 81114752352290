export type ServerSentEventType = 'CREATED' | 'UPDATED' | 'DELETED'

export enum ServerSentResourceType {
  ANAMNESIS = 'ANAMNESIS',
  APPOINTMENT = 'APPOINTMENT',
  CALL_TASK = 'CALL_TASK',
  TEXT_MESSAGE = 'TEXT_MESSAGE',
  CONVERSATION = 'CONVERSATION',
  TODO_LIST_TASK = 'TODO_LIST_TASK',
  PATIENT = 'PATIENT',
}

export type ServerSentEvent<T> = {
  data: T
  meta: {
    created: number
    eventType: ServerSentEventType
    id: string
    sequence: number
    resourceType: ServerSentResourceType
    source: {
      id: string
      name: string
    }
    version: 'V1'
  }
}
